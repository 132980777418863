@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap);
body {
  margin: 0;
  background-color: #e8e0fc !important;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: calc(100vh - 48px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

